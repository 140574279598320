$(document).ready(function () {

    $("nav a, .video-blocks .title").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + 60 + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });

    $(".go").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + 120 + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });

    $("#menu").mmenu({
        "extensions": [
                "position-right",
                "pagedim-black",
                "border-offset"
             ],
        classNames: {
            fixedElements: {
                fixed: "menu-link"
            },
            vertical: "expand",
        }
    });

    $("#menu").mhead();

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.ham').removeClass('active');
        $('.wrapper-menu').removeClass('open');
    });

    $("nav .mm-listitem__text").click(
        function (evnt) {
            evnt.preventDefault();
            api.close();
        }
    )

    $("#menu").mhead();

    $('.social, .tel, .lang').removeClass('mm-listitem');
    $('.social a, .tel a, .lang a').removeClass('mm-listitem__text');

    var highestBox = 0;
    jQuery('.why .item').each(function () {
        if (jQuery(this).height() > highestBox) {
            highestBox = jQuery(this).height();
        }
    });
    jQuery('.why .item').height(highestBox);

    var heightImg = $('.business img').height();
    $('.business .icon').height(heightImg);

    $('a.lightbox').fancybox();

    if ($(window).width() < 992) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > lastScrollTop) {
                $('.menu-link').addClass('mh-scrolledout');

            } else if (st == 0) {
                $('.menu-link').removeClass('mh-bg-color');
            } else {
                $('.menu-link').removeClass('mh-scrolledout');
            }

            if (st > 100) {
                $('.menu-link').addClass('mh-bg-color');
            }

            lastScrollTop = st;
        });
    }

    $("#phone").intlTelInput({preferredCountries: ["cy"]});
    
    $('.poster-text').each(function() {
        var linkText = $(this).text(),
            hoverText = $(this).data('hovertext');
        $(this).hover(
            function () {
                $(this).text(hoverText);
                console.log(hoverText);
            },function () {
                $(this).text(linkText);
            }
        );
    });
});
